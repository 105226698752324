import http from '../../../utils/services/http'

export const list = (limit, page, filtros, format = null) => {
    let url = '/api/pessoas?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (format && format !== 'html') {
        config.responseType = 'blob'
        config.timeout = 30000
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTags = (limit, page, filtros, format = null) => {
    let url = '/api/person-tags?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/pessoas/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const createPerson = (data) => {
    let url = '/api/pessoas'
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePerson = (person) => {
    let url = `/api/pessoas/${person.id}`
    return http.patch(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const editManyPerson = (request) => {
    let url = `/api/pessoas/editar`
    return http.put(url, request)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deletePerson = (person) => {
    let url = `/api/pessoas/${person.id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePersonTags = (person) => {
    let url = `/api/pessoa/${person.id}/tags`
    return http.post(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePersonOwner = (person) => {
    let url = `/api/pessoa/${person.id}/owner`
    return http.post(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePersonOrganization = (person) => {
    let url = `/api/pessoa/${person.id}/organizacao`
    return http.post(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listSeguidores = (person) => {
    let url = `/api/pessoas/${person.id}/seguidores`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const seguirPessoa = (person) => {
    let url = `/api/pessoas/${person.id}/seguir`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const naoSeguirPessoa = (person) => {
    let url = `/api/pessoas/${person.id}/seguir`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const criarNota = (person, data) => {
    let url = `/api/pessoas/${person.id}/nota`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadArquivoPessoa = (id, data) => {
    let url = `/api/pessoas/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoPessoa = (pessoa, arquivo, inline = false) => {
    let url = `/api/pessoas/${pessoa.id}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteArquivo = (person, arquivoId) => {
    let url = `/api/pessoas/${person.id}/arquivos/${arquivoId}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const historico = (person, tipo = null) => {
    let url = `/api/pessoas/${person.id}/historico`
    if (tipo) {
        url = url + '?tipo=' + tipo
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showNota = (person, notaId) => {
    let url = `/api/pessoas/${person.id}/notas/${notaId}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteNota = (person, notaId) => {
    let url = `/api/pessoas/${person.id}/notas/${notaId}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showNotaLogByReferId = (person, referId) => {
    let url = `/api/pessoas/${person.id}/notas/show-by-refer/${referId}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listProcessosRelacionados = (person) => {
    let url = `/api/pessoas/${person.id}/processos`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}


export const call = (data) => {
    let url = `/api/person/call`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const consultarCpfCnpj = (d, onlyInternal = false) => {
    let url = `/api/pessoas/consultaDocumentoOficial/${d}`
    if (onlyInternal) {
        url = url + '?onlyInternal=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const papeisByUser = () => {
    let url = `/api/pessoa/papeis`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
