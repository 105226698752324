<template>
  <window-content v-if="!loading">
    <div class="w-m-header">
      <h2 v-if="!bem.id">Novo Bem<span v-if="bem.processo && bem.processo.id"> para o processo {{bem.processo.numero|formataNumeroProcesso}}</span></h2>
      <div class="full-width" v-else>
        <span class="push-notification opened" v-if="bem.vendaDireta">Venda Direta</span>
        <span class="push-notification black" v-if="bem.destaque">Destaque</span>
        <span class="push-notification red" v-if="bem.bloqueadoLeilao">Bloqueado</span>
        <span class="push-notification blue" v-if="(bem.processo && bem.processo.id) || bem.processoNumero">Judicial</span>
        <span class="font-16">BEM ID {{bem.id}} - {{ bem.tipoString }}</span>
      </div>
      <btn-emissoes-bem v-if="bem.id" :bem="bem" />
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times" />
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body">
      <div class="bem-container" :class="bemCss">
        <div class="bem-menus">
          <e-btn @click="activateComponent(c)" v-for="c in components" :key="'component-' + c.id" :label="c.label" :class="{active: activeComponent === c.id}" :disable="c.disable"></e-btn>
        </div>
        <div class="bem-page">
<!--          <div class="helpers">
            <a>Cadastrar bem</a>
            <a>Voltar à lista de bens</a>
          </div>-->
          <erp-tabs v-show="activeComponent === 'cadastro'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="cadastro-basico">Básico</erp-tab-item>
            <erp-tab-item name="cadastro-marketplace">Marketplace</erp-tab-item>
            <erp-tab-item name="caracteristicas">Características</erp-tab-item>
            <erp-tab-item name="obs">Observações</erp-tab-item>
            <erp-tab-item name="localizacao">Localização</erp-tab-item>
            <erp-tab-item name="extra">Extra</erp-tab-item>
<!--            <erp-tab-item name="basico">Básico</erp-tab-item>
            <erp-tab-item name="avaliacao">Avaliação</erp-tab-item>
            <erp-tab-item name="imagens" v-if="bem.id">Imagens/Arquivos</erp-tab-item>
            <erp-tab-item name="obs">Observações</erp-tab-item>
            <erp-tab-item name="remocoes">Remoção</erp-tab-item>
            &lt;!&ndash;<erp-tab-item name="estada">Estada</erp-tab-item>&ndash;&gt; &lt;!&ndash; TODO: FAZER &ndash;&gt;
            <erp-tab-item name="leiloes" v-if="bem.id">Leilões e Lances</erp-tab-item>
            <erp-tab-item name="processo">Processo</erp-tab-item>-->
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'gestao'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="gestao">Gestão</erp-tab-item>
            <erp-tab-item name="gestao-debitos">Débitos</erp-tab-item>
            <erp-tab-item name="gestao-financeiro">Financeiro</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'processo'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="processo-basico">Principal</erp-tab-item>
<!--            <erp-tab-item name="processo-crm" disabled>CRM</erp-tab-item>-->
            <erp-tab-item name="processo-movimentacoes">Movimentações</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'avaliacao'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="avaliacao-basico">Básico</erp-tab-item>
            <erp-tab-item name="avaliacao-vistoria">Vistoria</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'arquivos'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="arquivos-basico">Fotos e Arquivos</erp-tab-item>
            <erp-tab-item name="arquivos-videos">Vídeos</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'remocao'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="remocao-basico">Principal</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'leiloes-propostas'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="leiloes-propostas-basico">Principal</erp-tab-item>
            <erp-tab-item name="leiloes-propostas-crm">CRM</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'checklist'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="checklist-basico">Principal</erp-tab-item>
            <erp-tab-item name="checklist-tarefas">Providências</erp-tab-item>
          </erp-tabs>
          <erp-tabs v-show="activeComponent === 'marketplace'" @change="changeTab" :active="tabActive">
            <erp-tab-item name="marketplace-basico">Principal</erp-tab-item>
          </erp-tabs>

          <layout-paper class="page-lg">
            <div v-show="tabActive === 'basico' || tabActive === 'cadastro-basico'" class="wrapper">
              <div class="wrapper-sm">
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 170px">
                    <erp-s-field
                        view="lr"
                        label="ID:"
                        label-width="90px"
                    >
                      <erp-input simple-border v-model="bem.id" readonly />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Comitente:"
                        class="m-r-n-xs"
                    >
                      <div class="m-l-xs"><comitente-input v-model="bem.comitente" :finded-data-obj="cache.comitenteSelected"
                                                           @updateSelected="(data) => cache.comitenteSelected = data" /></div>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row mr>

                  <e-col style="max-width: 244px; min-width: 294px">
                    <tipo-bem-select ref="tipoPai" required label-width="90px" simple-border label="Tipo de bem:"
                                     field-view="lr"
                                     v-model="bem.tipoPai" @load="loadTipoBemData" @selected="selecionaTipoBem" />
                  </e-col>

                  <e-col style="max-width: 244px; min-width: 294px">
                    <subtipo-bem-select v-if="tipoBemData && tipoBemData.length > 0" required :disable="!bem.tipoPai"
                                        :tipo="bem.tipoPai" :tipo-data="tipoBemData"
                                        label-width="90px" simple-border
                                        label="Classificação:" field-view="lr" v-model="bem.tipo"
                                        @selected="selecionaTipoBem" />
                  </e-col>

                  <e-col style="min-width: 184px">
                    <erp-s-field
                        view="lr"
                        label="Processo:"
                    >
                      <erp-input
                          simple-border
                          v-model="bem.processoNumero"
                          readonly
                          v-mask="'#######-##.####.#.##.####'"
                      >
                      </erp-input>
                    </erp-s-field>
                  </e-col>

                </e-row>

                <e-row mr>
                  <e-col class="input-font-bold" style="min-width: 184px">
                    <erp-s-field
                        view="lr"
                        label="Descrição:"
                        label-width="90px"
                    >
                      <erp-input
                          autofocus
                          simple-border
                          v-model="bem.descricao"
                      >
                      </erp-input>
                    </erp-s-field>
                  </e-col>

                  <e-col style="max-width: 167px">
                    <status-select label="Status:" placeholder="Selecione"
                                   field-view="lr"
                                   v-model="bem.status" />
                  </e-col>
                </e-row>

                <e-row mr>
                  <e-col style="max-width: 250px; min-width: 250px">
                    <erp-s-field
                        view="lr"
                        label="Entrada:"
                        label-width="90px"
                    >
                      <datetime-input v-model="bem.dataEntrada" simpleBorder />
                    </erp-s-field>
                  </e-col>

                  <e-col style="max-width: 200px; min-width: 200px">
                    <erp-s-field
                        view="lr"
                        label="Saída:"
                    >
                      <datetime-input v-model="bem.dataSaida" simpleBorder />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Pátio:"
                        class="m-r-n-xs"
                    >
                     <patio-input v-model="bem.patio" />
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Data Cadastro:"
                        label-width="90px"
                    >
                      <erp-input simple-border :value="$options.filters.formatDate(bem.createdAt)" readonly />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Criado:"
                    >
                      <erp-input simple-border :value="bem.createdByName" readonly />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Alterado:"
                    >
                      <erp-input simple-border :value="$options.filters.formatDate(bem.dateModified)" readonly />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field
                        view="lr"
                        label="Alteração:"
                    >
                      <erp-input simple-border :value="bem.modifiedByUser" readonly />
                    </erp-s-field>
                  </e-col>
                </e-row>
              </div>
              <bem-aba-basico
                  ref="abaBasico"
                  :tipo-bem-selecionado="tipoBemSelecionado"
                  :bem="bem"
                  :cache="cache"
                  @marcaSelected="(data) => cache.marcaSelected = data"
                  @modeloSelected="(data) => cache.modeloSelected = data"
              />
            </div>
            <marketplace ref="marketplace" v-show="tabActive === 'cadastro-marketplace'" class="wrapper" />
            <caracteristicas v-show="tabActive === 'caracteristicas'" class="wrapper" />
            <avaliacao v-if="tabActive === 'avaliacao' || tabActive === 'avaliacao-basico'" />
            <arquivos-bem :bem="bem" v-if="tabActive === 'imagens' || tabActive === 'arquivos-basico'" />
            <observacoes v-show="tabActive === 'obs'" class="wrapper" />
            <bem-remocao v-if="tabActive === 'remocoes' || tabActive === 'remocao-basico'" />
            <div v-show="tabActive === 'estada'" class="wrapper">
              <!-- TODO -->
            </div>
            <bem-extra v-show="tabActive === 'extra'" class="wrapper" />
            <bem-localizacao v-if="tabActive === 'localizacao'" />
            <bem-leiloes v-if="tabActive === 'leiloes' || tabActive === 'leiloes-propostas-basico'" />
            <bem-processo v-if="tabActive === 'processo' || tabActive === 'processo-basico'" />
            <movimentacao-processo v-if="tabActive === 'processo-movimentacoes'" />

            <gestao v-if="tabActive === 'gestao'" />
            <gestao-financeiro v-if="tabActive === 'gestao-debitos'" only-debitos />
            <gestao-financeiro v-if="tabActive === 'gestao-financeiro'" />

            <bem-vistorias v-if="tabActive === 'avaliacao-vistoria'" />

            <bem-videos v-if="tabActive === 'arquivos-videos'" />

            <bem-leilao-crm v-if="tabActive === 'leiloes-propostas-crm'" />

            <gestao-checklist v-if="tabActive === 'checklist-basico'" />
            <gestao-tarefas v-if="tabActive === 'checklist-tarefas'" />

            <marketplace-api v-if="tabActive === 'marketplace-basico'" />
          </layout-paper>
        </div>
      </div>
    </div>
    <!--<window-body>
      <div class="wrapper-md m-t-lg">
        <form v-on:submit.stop.prevent="save">
        </form>
      </div>
    </window-body>-->
    <div class="w-m-footer text-right footer-modern window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <e-btn :disable="loading" label="Analisar e Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" v-if="cracl(null, ['sl/bem/update']) || hasRole('ROLE_BEM')" />
    </div>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  //WindowHeader,
  // WindowBody,
  // WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  // ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import TipoBemSelect from '../include/TipoSelect'
import ComitenteInput from '../../../comitente/helpers/input/ComitenteInput'
import remocaoWindow from '../../../remocao/windows/remocao'
import DatetimeInput from '../../../../reuse/input/Datetime'
import StatusSelect from '../include/StatusSelect'
import SubtipoBemSelect from '../include/SubTipoSelect'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find, getCamposBem,
  newBem,
  updateBem,
  updateFotoBem
} from '../../../../domain/bem/services/index'

import BemAbaBasico from '../include/BemAbaBasico'
import TipoBemMixin from '../include/TipoBemMixin'
import {datePtToEn, datetimeToEn} from '@/utils/date'
import ArquivosBem from '../include/Arquivos'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import WindowLoading from "@/components/layout/window/Loading"
import {mockBem} from "@/domain/bem/helpers/Mock"

import LayoutPaper from "@/components/layout/LayoutPaper"
import Marketplace from "@/components/bem/components/include/Marketplace"
import BemExtra from "@/components/bem/components/include/Extra"
import Caracteristicas from "@/components/bem/components/include/Caracteristicas"
import Observacoes from "@/components/bem/components/include/Observacoes"
import Gestao from "@/components/bem/components/include/Gestao"
import GestaoFinanceiro from "@/components/bem/components/include/Financeiro"
import BemProcesso from "@/components/bem/components/include/Processo"
import MovimentacaoProcesso from "@/components/bem/components/include/MovimentacoesProcesso"
import Avaliacao from "@/components/bem/components/include/Avaliacao"
import BemVistorias from "@/components/bem/components/include/Vistorias"
import BemVideos from "@/components/bem/components/include/Videos"
import BemLeilaoCrm from "@/components/bem/components/include/LeilaoCrm"
import BemLeiloes from "@/components/bem/components/include/Leiloes"
import GestaoChecklist from "@/components/bem/components/include/Checklist"
import GestaoTarefas from "@/components/bem/components/include/Tarefas"
import MarketplaceApi from "@/components/bem/components/include/MarketplaceApi"
import BemRemocao from "@/components/bem/components/include/Remocao"
import BemLocalizacao from "components/bem/components/include/Localizacao"
import PatioInput from "components/patio/helpers/input/PatioInput"
import BtnEmissoesBem from "components/bem/components/include/BtnEmissoesBem"

let loadingCampos = false

export default {
  name: 'BemWindow',
  provide: function () {
    return {
      bemContainer: this,
      remocaoContainer: this
    }
  },
  mixins: [TipoBemMixin],
  directives: {money: VMoney},
  props: ['id', 'processo', 'router'],
  data () {
    const mock = JSON.parse(JSON.stringify(mockBem))
    this.parseProcesso(mock)
    return {
      components: [
        {
          id: 'cadastro',
          label: 'Cadastro',
          disable: false
        },
        {
          id: 'gestao',
          default: 'gestao',
          label: 'Gestão e Débitos',
          disable: false
        },
        {
          id: 'processo',
          label: 'Processo',
          disable: false
        },
        {
          id: 'avaliacao',
          label: 'Avaliação',
          disable: false
        },
        {
          id: 'arquivos',
          default: 'arquivos-basico',
          label: 'Imagens, Vídeos e Arquivos',
          disable: false
        },
        {
          id: 'remocao',
          label: 'Remoção',
          disable: false
        },
        {
          id: 'leiloes-propostas',
          label: 'Leilões e Propostas',
          disable: false
        },
        {
          id: 'checklist',
          label: 'Checklist / Providências'
        },
        {
          id: 'marketplace',
          label: 'Marketplace / API'
        }
      ],
      activeComponent: 'cadastro',
      isInitialize: false,
      money: REAL_BRL,
      tabActive: 'cadastro-basico',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      bem: mock,
      tipoBemData: [],
      tipoBemSelecionado: null,
      upload: {
        tipoArquivo: null,
        descricao: null
      },
      deleteFoto: false,
      cache: {
        comitenteSelected: null,
        remocaoSelected: null,
        marcaSelected: null,
        modeloSelected: null
      },
      checklistJudicial: {
        // id: null,
        advogadoConstituidoAutor: false,
        advogadoConstituidoReu: false,
        intimacaoPessoalPenhora: false,
        dataIntimacaoPessoalPenhora: null,
        intimacaoPessoalAvaliacao: false,
        dataIntimacaoPessoalAvaliacao: null,
        intimacaoPessoalLeilao: false,
        dataIntimacaoPessoalLeilao: null,
        coproprietario: false,
        observacoesCoproprietario: null,
        intimacaoConjuge: false,
        dataIntimacaoConjuge: null,
        estadoCivil: null,
        dataRgi: null,
        averbacoesRgi: null,
        cientificacoes: false,
        observacoesCientificacoes: null,
        condominio: false,
        condominioAutor: false,
        valorDebito: null,
        levantamentoDetran: false,
        dataLevantamentoDetran: null,
        observacoes: null
        // bem: null
      },
      fake: {
        porcentagemMinimoSobreValorMercado: null,
        porcentagemAvaliacaoSobreValorMercado: null
      }
    }
  },
  mounted () {
    this.id && this.load()
  },
  computed: {
    bemCss () {
      const css = []
      this.isVeiculo && css.push('bem-is-veiculo')
      this.isImovel && css.push('bem-is-imovel')
      return css
    }
  },
  watch: {
    'bem.tipo'() {
      this.$nextTick(() => {
        this.getCamposExtras()
      })
    },
    'bem.tipoPai'() {
      this.$nextTick(() => {
        if (!this.bem.id && this.bem.tipoPai == '1') {
          if (!this.bem.conservacao) {
            this.$refs.abaBasico.$refs.conservacaoSelect.autoSet('Regular')
          }
        }
      })
    },
    'bem.valorMercado' (v) {
      this.calcularPorcentagemAvaliacaoSobreValorMercado()
      this.calcularPorcentagemMinimoSobreValorMercado()
    },
    'bem.valorMinimo' (v) {
      this.calcularPorcentagemAvaliacaoSobreValorMercado()
      this.calcularPorcentagemMinimoSobreValorMercado()
    },
    'bem.valorAvaliacao' (v) {
      this.calcularPorcentagemAvaliacaoSobreValorMercado()
    },
    'bem.siteObservacao' (v) {
      if (v === null) {
        this.bem.siteObservacao = ''
      }
    }
  },
  methods: {
    parseProcesso (mock) {
      const processo = this.processo || this.bem && this.bem.processo
      if (processo) {
        mock.processo = processo
        if (mock.processo && mock.processo.id) {
          mock.processoNumero = mock.processo.numero
          if (processo.vara) {
            mock.processoVara = processo.vara.nome
            if (processo.vara.comarca) {
              mock.processoComarca = processo.vara.comarca.nome
            }
          }
          if (processo.partes && processo.partes.length) {
            processo.partes.map(parte => {
              if (parte.tipo && parte.tipo.codigo === 'autor') {
                mock.processoExequente = parte.pessoa.name
              }
              if (parte.tipo && parte.tipo.codigo === 'executado') {
                mock.processoExecutado = parte.pessoa.name
              }
            })
          }
          console.log(mock.processo)
        }
      }
    },
    activateComponent (c) {
      if (c.default) {
        this.tabActive = c.default
      } else {
        this.tabActive = c.id + '-basico'
      }
      this.$nextTick(() => {
        this.activeComponent = c.id
      })
    },
    load () {
      this.isInitialize = true
      return find(this.id || this.bem.id)
        .then(response => {
          let data = response.data
          if (data.dataEntrada && data.dataEntrada.date) {
            data.dataEntrada = date.formatDate(data.dataEntrada.date, 'DD/MM/YYYY HH:mm')
          }
          if (data.dataSaida && data.dataSaida.date) {
            data.dataSaida = date.formatDate(data.dataSaida.date, 'DD/MM/YYYY HH:mm')
          }
          if (data.dataHigienizacao && data.dataHigienizacao.date) {
            data.dataHigienizacao = date.formatDate(data.dataHigienizacao.date, 'DD/MM/YYYY')
          }

          data.valorInicial = 'R$ ' + this.$options.filters.moeda(data.valorInicial || 0)
          data.valorAvaliacao = 'R$ ' + this.$options.filters.moeda(data.valorAvaliacao || 0)
          data.valorMercado = 'R$ ' + this.$options.filters.moeda(data.valorMercado || 0)
          data.valorMinimo = 'R$ ' + this.$options.filters.moeda(data.valorMinimo || 0)
          data.valorDebitos = 'R$ ' + this.$options.filters.moeda(data.valorDebitos || 0)

          if (!data.remocao) {
            data.remocao = {
              id: null,
              solicitacoes: []
            }
          }

          if (data.tipo) {
            data.tipoString = data.tipo.nome
            if (data.tipo.parent) {
              data.tipoPai = data.tipo.parent.id
              data.tipo = data.tipo.id
            } else {
              data.tipo = data.tipoPai = data.tipo.id
            }
          }

          if (data.extra && !data.extra.avaliacaoTerceiros) {
            data.extra.avaliacaoTerceiros = {
              avaliador: null,
              remessa: null,
              valorMercado: null,
              relatorio: null
            }
          }

          if (data.comitente && data.comitente.id) {
            this.cache.comitenteSelected = data.comitente
            data.comitente = data.comitente.id
          }

          if (data.marca && data.marca.id) {
            this.cache.marcaSelected = data.marca
            data.marca = data.marca.id
          }

          if (data.modelo && data.modelo.id) {
            this.cache.modeloSelected = data.modelo
            data.modelo = data.modelo.id
          }

          if (data.fipeAnoModelo && data.fipeAnoModelo.id) {
            data.fipeAnoModelo = data.fipeAnoModelo.id
          }

          if (data.tipoCombustivel && data.tipoCombustivel.id) {
            data.tipoCombustivel = data.tipoCombustivel.id
          }

          if (data.cor && data.cor.id) {
            data.cor = data.cor.id
          }

          if (data.conservacao && data.conservacao.id) {
            data.conservacao = data.conservacao.id
          }

          if (data.tipoApreensao && data.tipoApreensao.id) {
            data.tipoApreensao = data.tipoApreensao.id
          }

          if (data.acessorios && data.acessorios.length > 0) {
            data.acessorios = data.acessorios.slice().map(a => {
              return a.acessorio
            })
          }

          if (!data.extra) {
            data.extra = JSON.parse(JSON.stringify(mockBem.extra))
          }
          if (!data.imovel) {
            data.imovel =  JSON.parse(JSON.stringify(mockBem.imovel))
          }
          if (!data.veiculo) {
            data.veiculo =  JSON.parse(JSON.stringify(mockBem.veiculo))
          }

          if (data.imovel.finalidade && data.imovel.finalidade.id) {
            data.imovel.finalidade = data.imovel.finalidade.id
          }
          /*if (response.data.tipo !== null) {
            this.$nextTick(() => {

            })
          }*/
          // let mock = JSON.parse(JSON.stringify(mockBem))
          // this.bem = Object.assign(mock, data)
          if (data.siteDescricao === null) {
            data.siteDescricao = ''
          }

          if (data.siteObservacao === null) {
            data.siteObservacao = ''
          }

          if (data.checklistJudicial) this.montaChecklistJudicial(data)

          if (!data.tags) {
            data.tags = []
          } else {
            data.tags = data.tags.map(tag => {
              return tag.name
            })
          }
          if (!data.caracteristicas) {
            data.caracteristicas = []
          }
          if (!data.campos) {
            data.campos = []
          }

          if (data.sites) {
            data.sites = data.sites.map(s => s.id)
          } else {
            data.sites = []
          }

          if (!data.processo) {
            data.processo = null
          }

          /*if (data.patio && data.patio.id) {
            data.patio = data.patio.id
          }

          if (data.vaga && data.vaga.id) {
            data.vaga = data.vaga.id
          }*/

          this.bem = data
          this.$nextTick(() => {
            this.loading = false
            window.setTimeout(() => {
              this.isInitialize = false
            }, 2000)
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o bem, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    openRemocao: remocaoWindow,
    loadTipoBemData (data) {
      this.tipoBemData = data
    },
    selecionaTipoBem (tipo) {
      this.tipoBemSelecionado = tipo
      !this.isInitialize && tipo && this.getCamposExtras()
    },
    getCamposExtras () {
      this.$nextTick(() => {
        let data = {}
        if (this.bem.id) {
          data.bem = this.bem.id
        }
        if (this.bem.tipo) {
          data.tipo = this.bem.tipo.id ?? this.bem.tipo
        }
        if (!data.bem && !data.tipo) return
        if (loadingCampos) return
        loadingCampos = true
        getCamposBem(data)
            .then(response => {
              loadingCampos = false
              console.log(response)
              this.bem.campos = response.data
            })
            .catch(error => {
              loadingCampos = false
              this.alertApiError(error)
            })
      })
    },
    save () {
      let data
      try {
        console.log('Save')
        data = JSON.parse(JSON.stringify(this.bem))

        if (data.bloqueadoLeilao) {
          if (Number(data.status) === 4) {
            //throw new Error('Este bem está em leilão. Para bloqueá-lo, retire-o do leilão')
            alert('Este bem está em leilão, como você está bloqueando, o status dele nos leilões ativos será atualizado para Retirado.')
          }
        }

        data.processoNumero = data.processoNumero?.replace(/\D/g, '')

        if (!data.siteTitulo) {
          data.siteTitulo = data.descricao
        }
        if (!data.siteDescricao) {
          data.siteDescricao = data.descricao
        }

        data.valorInicial = convertRealToMoney(data.valorInicial)
        data.valorAvaliacao = convertRealToMoney(data.valorAvaliacao)
        data.valorMinimo = convertRealToMoney(data.valorMinimo)
        data.valorMercado = convertRealToMoney(data.valorMercado)
        data.valorDebitos = convertRealToMoney(data.valorDebitos)

        if (data.dataEntrada) {
          data.dataEntrada = datetimeToEn(data.dataEntrada)
        }
        if (data.dataSaida) {
          data.dataSaida = datetimeToEn(data.dataSaida)
        }
        if (data.dataHigienizacao) {
          data.dataHigienizacao = datePtToEn(data.dataHigienizacao)
        }

        if (data.acessorios && data.acessorios.length > 0) {
          data.acessorios = data.acessorios.map(a => {
            return {acessorio: a.id}
          })
        }

        if (data.pericias && data.pericias.length > 0) {
          data.pericias = data.pericias.map(p => {
            if (p.id) {
              return {id: p.id, pericia: p.pericia.id || p.pericia}
            }
            return p
          })
        }

        if (data.camposExtras && data.camposExtras.length > 0) {
          data.camposExtras = data.camposExtras.map(p => {
            if (p.campo && p.campo.id) {
              p.campo = p.campo.id
            }
            return p
          })
        }

        if (this.$refs.marketplace) {
          data.siteDescricao = this.$refs.marketplace.$refs.editorDescricao.htmlContent()
          data.siteObservacao = this.$refs.marketplace.$refs.editorObservacao.htmlContent()
        }

        if (data.cidade && data.cidade.label) {
          data.cidade = data.cidade.label
        }

        if (data.processo && data.processo.id) {
          data.processo = data.processo.id
        }

        if (!this.isImovel) {
          delete data.imovel
        } else {
          data.imovel.valorIptu = convertRealToMoney(data.imovel.valorIptu)
          data.imovel.valorCondominio = convertRealToMoney(data.imovel.valorCondominio)
          data.imovel.valorOutrasDespesas = convertRealToMoney(data.imovel.valorOutrasDespesas)
        }

        if (!this.isVeiculo) {
          delete data.veiculo
        } else {
        }

        if (data.patio && data.patio.id) {
          data.patio = data.patio.id
        }

        if (data.vaga && data.vaga.id) {
          data.vaga = data.vaga.id
        }

        data.extra = JSON.stringify(data.extra)
        this.processaChecklist(data)

        if (this.$refs.abaBasico.$imagem) {
          this.$imagem = this.$refs.abaBasico.$imagem
        }
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        return
      }
      this.loading = true
      this.status = 'Processando dados do bem'
      let method = data.id ? updateBem(data.id, data) : newBem(data)
      method
        .then(({data}) => {
          this.bem.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              this.loading = true
              this.load()
              /* window.setTimeout(() => {
                this.loading = false
                this.success = false
              }, 3000) */
              this.dg()
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
            this.$imagem && delete this.$imagem
            cbSuccess()
          } else if (this.$imagem) {
            this.status = 'Analisando e processando arquivos do bem'
            updateFotoBem(this.bem.id, {data: this.$imagem, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$imagem
              })
              .catch((error) => {
                alert('Impossível enviar a imagem principal do bem, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }
        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    calcularPorcentagemAvaliacaoSobreValorMercado () {
      this.$nextTick(() => {
        let mercado = convertRealToMoney(this.bem.valorMercado)
        let avaliacao = convertRealToMoney(this.bem.valorAvaliacao)
        let calc = ((avaliacao * 100) / mercado).toFixed(2)
        if (isNaN(calc) || !isFinite(calc)) {
          return '0%'
        }
        this.fake.porcentagemAvaliacaoSobreValorMercado = calc + '%'
      })
    },
    calcularPorcentagemMinimoSobreValorMercado () {
      this.$nextTick(() => {
        let mercado = convertRealToMoney(this.bem.valorMercado)
        let minimo = convertRealToMoney(this.bem.valorMinimo)
        let calc = ((minimo * 100) / mercado).toFixed(2)
        if (isNaN(calc) || !isFinite(calc)) {
          return '0%'
        }
        this.fake.porcentagemMinimoSobreValorMercado = calc + '%'
      })
    },
    processaChecklist (data) {
      const checklistJudicial = JSON.parse(JSON.stringify(this.checklistJudicial))
      checklistJudicial.valorDebito = convertRealToMoney(checklistJudicial.valorDebito)

      const nomesDatas = [
        'dataIntimacaoPessoalAvaliacao',
        'dataIntimacaoPessoalPenhora',
        'dataIntimacaoPessoalLeilao',
        'dataIntimacaoConjuge',
        'dataLevantamentoDetran',
        'dataRgi',
        'valorDebitoAtualizadoEm'
      ]

      for (const nomeData of nomesDatas) {
        if (!checklistJudicial[nomeData]) continue
        checklistJudicial[nomeData] = datePtToEn(checklistJudicial[nomeData])
      }

      data.checklistJudicial = checklistJudicial
    },
    montaChecklistJudicial (data) {
      const checklistJudicial = JSON.parse(JSON.stringify(data.checklistJudicial))

      const nomesDatas = [
          'dataIntimacaoPessoalAvaliacao',
          'dataIntimacaoPessoalPenhora',
          'dataIntimacaoPessoalLeilao',
          'dataIntimacaoConjuge',
          'dataLevantamentoDetran',
          'dataRgi',
          'valorDebitoAtualizadoEm'
      ]
      for (const nomeData of nomesDatas) {
        if (!checklistJudicial[nomeData]?.date) continue
        checklistJudicial[nomeData] = date.formatDate(checklistJudicial[nomeData].date, 'DD/MM/YYYY')
      }

      checklistJudicial.valorDebito = 'R$ ' + this.$options.filters.moeda(checklistJudicial.valorDebito || 0)
      this.checklistJudicial = checklistJudicial
    }
  },
  components: {
    BtnEmissoesBem,
    PatioInput,
    BemLocalizacao,
    BemRemocao,
    MarketplaceApi,
    GestaoTarefas,
    GestaoChecklist,
    BemLeiloes,
    BemLeilaoCrm,
    BemVideos,
    BemVistorias,
    Avaliacao,
    MovimentacaoProcesso,
    BemProcesso,
    GestaoFinanceiro,
    Gestao,
    Observacoes,
    Caracteristicas,
    BemExtra,
    Marketplace,
    LayoutPaper,
    WindowLoading,
    ECol,
    ERow,
    ArquivosBem,
    BemAbaBasico,
    // TipoArquivoSelect,
    // ConservacaoSelect,
    // CombustivelSelect,
    // CorSelect,
    // ModeloSelect,
    // MarcaSelect,
    SubtipoBemSelect,
    // UfSelect,
    // WindowSuccess,
    // TimerCloseWindow,
    StatusSelect,
    DatetimeInput,
    ComitenteInput,
    TipoBemSelect,
    // WindowLoading,
    // WindowFooter,
    // WindowBody,
    //WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel,
    // UEditor,
    // URadio
  }
}
</script>
