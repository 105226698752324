import http from '../utils/services/http'
import env from '../env'
import {Provider} from 'uloc-vue-auth'

export const worker = async () => {
    return new Promise((resolve, reject) => {
        try {
            if (typeof process.env.VUE_APP_WORKER_SERVER === 'undefined' || !process.env.VUE_APP_WORKER_SERVER) {
                resolve()
                return
            }
            http.get(process.env.VUE_APP_WORKER_SERVER + '/client', {
                transformRequest: (data, headers) => {
                    if (typeof headers.common.Authorization !== 'undefined') {
                        delete headers.common.Authorization
                    }
                    return data
                },
                timeout: 5000
            })
                .then(response => {
                    if (typeof response.data.api !== 'undefined' && response.data.api) {
                        env.api = response.data.api
                        http.defaults.baseURL = env.api
                        const _provider = Provider.get()
                        _provider.urlApi = env.api
                    }
                    if (typeof response.data.websocket !== 'undefined' && response.data.websocket) {
                        env.websocket = response.data.websocket
                    }
                    resolve(response)
                })
                .catch(({response}) => {
                    reject(response)
                })
        } catch (e) {
            resolve()
        }
    })
}
