const disableAlert = () => {
  alert('Função desabilitada para suas configurações')
}

const ruralLicenseAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
const configureAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}

const configureFinanceiroAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
export default [
  {
    id: 'principal',
    menuName: 'Principal',
    components: [
      {
        component: 'Minha conta',
        features: [
          {name: 'Dashboard', icon: 'dashboard', href: '/#/'},
          {name: 'Meu perfil', icon: 'perfil', href: '/#/perfil'},
          {name: 'Mensagens', icon: 'mensagens', tip: null, href: '/#/perfil/mensagens'},
          {name: 'Configurações', icon: 'config', href: '/#/perfil/configuracoes'},
        ]
      },
      /*{
        component: 'Intranet',
        features: [
          {name: 'Social Network', icon: 'network', clickEvent: disableAlert},
          {name: 'Base de conhecimento', icon: 'knowledge', clickEvent: disableAlert}
        ]
      },*/
      {
        component: 'Colaborativo',
        features: [
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas'},
          // {name: 'Minhas metas', icon: 'metas', clickEvent: configureAlert},
          // {name: 'Arquivos', icon: 'files', clickEvent: configureAlert}
        ]
      },
      {
        component: 'Ajuda',
        right: true,
        hideLabel: true,
        features: [
          {name: 'Ajuda e Documentação', icon: 'help', href: 'https://docs.suporteleiloes.com', target: '_blank'}
        ]
      }
    ]
  },
  {
    id: 'gerencia',
    roles: ['ROLE_GERENCIA'],
    menuName: 'Console',
    components: [
      {
        component: 'Gerência',
        features: [
          // s{name: 'Dashboard', icon: 'home', href: '/#/'},
          {name: 'Clientes', icon: 'contract', href: '/#/console/carteira/clientes'},
          {name: 'Servidores', icon: 'api', href: '/#/gerencia/servidores'}
        ]
      },
      {
        component: 'Suporte',
        features: [
          {name: 'Dashboard', icon: 'reports', href: '/#/console/suporte'},
          {name: 'Projetos', icon: 'contract', href: '/#/console/suporte/projetos'},
          {name: 'Tarefas', icon: 'tasks', href: '/#/console/suporte/tarefas'}
        ]
      }
    ]
  },
  {
    id: 'bens',
    menuName: 'Bens',
    components: [
      {
        component: 'Administração de Bens',
        features: [
          {name: 'Gestão de Bens', icon: 'mat', href: '/#/bens/lista'},
          {name: 'Imóveis', icon: 'house', href: '/#/bens/lista#imoveis'},
          {name: 'Veículos', icon: 'car', href: '/#/bens/lista#veiculos'},
          {name: 'Vistoria', icon: 'metas', href: '/#/vistoria', roles: ['!ROLE_COMITENTE']},
          {name: 'Propostas', icon: 'contract', href: '/#/propostas-venda-direta', roles: ['!ROLE_COMITENTE']},
          {name: 'Dashboard Cadastral', icon: 'monitor', href: '/#/insights/bens', roles: ['!ROLE_COMITENTE']}
          /*{name: 'Cálculo de Estadas', icon: 'calc', clickEvent: disableAlert}*/
        ]
      },
      {
        component: 'Processo de Remoção',
        roles: ['!ROLE_COMITENTE'],
        features: [
          {name: 'Remocão', icon: 'truck', href: '/#/remocao'},
          {name: 'Rastreamento', icon: 'track', href: '/#/remocao/monitor'},
          {name: 'Pátios', icon: 'map-pointer', href: '/#/patios/lista'},
          {name: 'Estoque', icon: 'bank', href: '/#/patio/estoque'},
        ]
      },
      /*{
        component: 'Gestão de Processos',
        features: [
          {name: 'Processos', icon: 'archive', href: '/#/processo'},
        ]
      },*/
      {
        component: 'Configurações',
        roles: ['!ROLE_COMITENTE'],
        features: [
          {name: 'Cadastro Básico', icon: 'register', href: '/#/cadastro', roles: ['!ROLE_COMITENTE'], acl: () => ['sl/cadastro-basico']},
          {name: 'Configurações Globais', icon: 'config', href: "/#/configuracoes/globais", roles: () => ['ROLE_ADMIN']},
        ]
      }
    ]
  },
  {
    id: 'leiloes',
    menuName: 'Leilões',
    // acl: () => ['sl/leilao/list'],
    components: [
      {
        component: 'Administração de Leilão',
        features: [
          {name: 'Leilões', icon: 'auction', href: "/#/leiloes"},
          // {name: 'PDS', icon: 'terminal', clickEvent: disableAlert},
          {name: 'Pagamento de Lotes', icon: 'thermal-print', href: "/#/pda?app=app-iniciar-atendimento-leilao", roles: ['!ROLE_COMITENTE']},
          {name: 'Ponto de Atendimento', icon: 'contact', href: "/#/pda", roles: ['!ROLE_COMITENTE']},
          {name: 'Auditório Unificado', icon: 'monitor', href: "/#/leiloes/auditorio", roles: ['!ROLE_COMITENTE']},
          {name: 'Fila', icon: 'metas', href: "/#/fila-vendas-leilao", roles: ['!ROLE_COMITENTE']}
        ]
      },
      {
        component: 'Fiscal',
        features: [
          {name: 'Notas', icon: 'archive', href: "/#/notas"},
        ]
      }
    ]
  },
  {
    id: 'gestao',
    menuName: 'Gestão',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'Gestão',
        features: [
          {name: 'Pessoas', icon: 'user', href: '/#/pessoas'},
          {name: 'Processos', icon: 'archive', href: '/#/processo/'},
          {name: 'Bens', icon: 'mat', href: '/#/bens/lista'},
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas'},
          {name: 'Produtividade', icon: 'financialbook', href: '/#/processo/produtividade'},
          {name: 'Documentos', icon: 'template', href: '/#/documentos/'},
          {name: 'Validade de Documentos', icon: 'metas', href: '/#/validade-documentos/'},
          {name: 'Controle de RGI', icon: 'metas', href: '/#/gestao/controle-rgi'},
          {name: 'Parceiros', icon: 'contract', href: '/#/parceiros'},
        ]
      },
      {
        component: 'Automação de Processos',
        features: [
          {name: 'Andamentos', icon: 'reports', href: '/#/andamentos'},
          {name: 'Robôs PJe', icon: 'api', href: '/#/processo/', clickEvent: disableAlert},
        ]
      },
      {
        component: 'Gestão de Bens',
        features: [
          {name: 'Imóveis', icon: 'house', href: '/#/bens/lista#imoveis'},
          {name: 'Veículos', icon: 'car', href: '/#/bens/lista#veiculos'}
        ]
      }
      /*{
        component: 'Gestão de Documentos',
        features: [
        ]
      },*/
    ]
  },
  {
    id: 'crm',
    menuName: 'CRM',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'CRM',
        features: [
          {name: 'Negócios', icon: 'contract', href: '/#/negocios'},
          {name: 'Callcenter', icon: 'contact', href: '/#/callcenter'},
        ]
      },
      {
        component: 'Marketing',
        features: [
          {name: 'Campanhas', icon: 'mkt', href: "/#/marketing/campanhas"},
          {name: 'Gerador', icon: 'template', href: "/#/marketing/gerador"},
        ]
      },
      {
        component: 'Comunicação',
        features: [
          {name: 'Aviso de Entrega de Itens', icon: 'metas', href: "/#/entrega-itens"},
        ]
      }
    ]
  },
  {
    id: 'comitentes',
    menuName: 'Comitentes',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        features: [
          {name: 'Comitentes', icon: 'user', href: "/#/comitentes/lista"}
        ]
      },
      {
        component: 'Integração',
        features: [
          {name: 'Grupo Porto', icon: 'terminal', href: "/#/integra/grupo-porto",},
          {name: 'Tribunais', icon: 'terminal', clickEvent: disableAlert},
          {name: 'Resale', icon: 'terminal', href: "/#/integra/resale"},
        ]
      }
    ]
  },
  {
    id: 'arrematantes',
    menuName: 'Arrematantes',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'Administração de Arrematantes',
        features: [
          {name: 'Arrematantes', icon: 'user', href: "/#/arrematantes/lista"},
          {name: 'Exportar base', icon: 'export', href: "/#/arrematantes/exportar"},
          {name: 'Campanhas de comunicação', icon: 'chat', href: "/#/marketing/campanhas#arrematantes"},
          /*{name: 'Monitoramento', icon: 'monitor', href: "/#/arrematantes/monitor"},*/
          {name: 'Aprovação de cadastros', icon: 'approved', href: "/#/arrematantes/aprovacao"},
          {name: 'Habilitação', icon: 'list', href: "/#/arrematantes/habilitacao"}
        ]
      }
    ]
  },
  {
    id: 'administrar-site',
    menuName: 'Sites',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'Configurações',
        features: [
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites"},
          {name: 'Menus', icon: 'config', href: "/#/website/menus"},
        ]
      },
      {
        component: 'Conteúdo Sites',
        features: [
          {name: 'Banners', icon: 'files', href: "/#/website/banners"},
          {name: 'Popup', icon: 'files', href: "/#/website/banners?type=popups"},
          {name: 'Páginas', icon: 'template', href: "/#/website/paginas"},
        ]
      },
      {
        component: 'Blog',
        features: [
          {name: 'Publicações', icon: 'template', href: "/#/blog/posts"},
          {name: 'Categorias', icon: 'metas', href: "/#/website/menus"},
        ]
      }
    ]
  },
  {
    id: 'financeiro',
    menuName: 'Financeiro',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'Financeiro',
        features: [
          {name: 'Resumo', icon: 'reports', href: '/#/financeiro'},
          {name: 'Contas a Pagar', icon: 'pay1', href: '/#/financeiro/contas#pagar'},
          {name: 'Contas a Receber', icon: 'pay2', href: '/#/financeiro/contas#receber'},
          {name: 'Extrato', icon: 'invoice', href: '/#/financeiro/contas#extrato'},
        ]
      },
      /* {
        component: 'Compras',
        features: [
          {name: 'Compras', icon: 'cart', clickEvent: configureAlert},
        ]
      }, */
      {
        component: 'Consultas',
        features: [
          {name: 'Consulta CPF/CNPJ', icon: 'cpf', clickEvent: disableAlert},
          // {name: 'Inadimplentes', icon: 'proccess', clickEvent: configureFinanceiroAlert}, @TODO
        ]
      },
      {
        component: 'Contabilidade',
        features: [
          {name: 'Plano de Contas', icon: 'charts', href: '/#/financeiro/categorias'},
          // {name: 'Orçamento Anual', icon: 'finalcialbook', clickEvent: configureAlert},
          // {name: 'NF-e recebidas', icon: 'financialbook', clickEvent: configureFinanceiroAlert},
          // {name: 'Contratos', icon: 'contract', clickEvent: configureFinanceiroAlert}, @TODO
        ]
      },
      {
        component: 'Cadastros',
        right: true,
        features: [
          {name: 'Fornecedores', icon: 'userwork', href: "/#/pessoas?paper=fornecedor"},
          {name: 'Contas', icon: 'bank', href: '/#/cadastro/bancos'},
          {name: 'Cento de Custo', icon: 'centercost', href: '/#/financeiro/cc'},
        ]
      },
    ]
  },
  /*{
    id: 'rh',
    menuName: 'RH',
    components: []
  },*/
  {
    id: 'relatorios',
    menuName: 'Relatórios',
    roles: [],
    components: [
      {
        component: 'Emissão',
        features: [
          {name: 'Relatórios Gerenciais', icon: 'reports', href: "/#/relatorios"},
        ]
      },
      {
        component: 'Auditoria e Logs',
        features: [
          {name: 'Log Operacional', icon: 'log', href: '/#/relatorios/operacional/logs'},
          {name: 'API Error', icon: 'api', clickEvent: configureAlert},
          {name: 'Comunicação Geral', icon: 'chat', clickEvent: configureAlert}
        ]
      }
    ]
  },
  {
    id: 'configuracoes',
    menuName: 'Configurações',
    roles: ['!ROLE_COMITENTE'],
    components: [
      {
        component: 'Cadastro Básico',
        features: [
          {name: 'Cadastro Básico', icon: 'register', href: "/#/cadastro"},
          {name: 'Templates e Documentos', icon: 'template', href: "/#/documentos"},
          {name: 'Variáveis', icon: 'archive', href: "/#/configuracoes/variaveis"},
          {name: 'Taxas', icon: 'percent', href: "/#/configuracoes/taxas"}
        ]
      },
      {
        component: 'Administração',
        features: [
          {name: 'Usuários', icon: 'user', href: "/#/configuracoes/usuarios"},
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites"},
          {name: 'Formulários', icon: 'metas', href: "/#/forms"},
        ]
      },
      {
        component: 'Sistema',
        features: [
          {name: 'Importação', icon: 'files', href: "/#/configuracoes/importador"},
          {name: 'Configurações Globais', icon: 'config', href: "/#/configuracoes/globais"},
        ]
      },
      {
        component: 'Ajuda',
        right: true,
        hideLabel: true,
        features: [
          {name: 'Suporte', icon: 'contact', href: '/#/console/suporte'},
        ]
      }
    ]
  }
]
