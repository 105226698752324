<template>
  <div>
    <div v-if="title" class="font-sb font-12 m-b-sm">Solicitações</div>
    <div class=" flex justify-between" style="height: 160px">
      <div class="m-t col-grow flex justify-between h-full" style="margin: auto">
        <e-window-table v-if="hasReboquista" class="col-grow h-full"
                        :columns="['ID', 'Data', 'Origem', 'Destino', 'Reboquista', 'Status', 'Opções']"
        >
          <e-tr v-for="(data, index) in model" :key="index">
            <e-td><span v-if="data.id">{{ data.id }}</span><span v-else class="text-primary">a registrar</span>
            </e-td>
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy') }}</e-td>
            <e-td class="text-nowrap_" v-if="data.origem.cidade">{{data.origem.nome}}, {{ `${data.origem.cidade ? data.origem.cidade.nome : data.origem.nome} - ${getUf(data.origem)}` }}</e-td>
            <e-td class="text-nowrap_" v-else>{{data.origem.nome}}, {{data.origem.cidadeOld}} - {{data.origem.ufOld}}</e-td>
            <e-td class="text-nowrap_" v-if="data.destino.cidade">{{data.destino.nome}}, {{ `${data.destino.cidade ? data.destino.cidade.nome : data.destino.nome} - ${getUf(data.destino)}` }}</e-td>
            <e-td class="text-nowrap_" v-else>{{data.destino.nome}}, {{data.destino.cidadeOld}} - {{data.destino.ufOld}}</e-td>
            <e-td>{{ data.reboquista.nome || data.reboquista.pessoa.name }}</e-td>
            <e-td class="text-nowrap">
              <div v-if="status(data.status)" class="flex items-center no-wrap text-nowrap">
                <status-solicitacao :solicitacao="data" />
              </div>
            </e-td>
            <e-td>
              <a v-if="status(data.status)" @click="openSolicitacao(data.remocao.id, data.id)"><i class="erp-icon search min"></i> </a>
              <a v-if="!data.id" @click="$emit('edit', data)"><i class="fa fa-edit"></i> </a>
            </e-td>
          </e-tr>

          <e-table-footer slot="footer" class="flex flex-center">
            <e-table-footer-result class="m-r text-right">Total de remoções = {{model.length}}</e-table-footer-result>
            <e-table-footer-result class="m-r text-right">Remoções pendentes = {{pendentes}}</e-table-footer-result>
            <e-table-footer-result class="text-right">Remoções concluídas = {{concluidas}}</e-table-footer-result>
          </e-table-footer>
        </e-window-table>
        <e-window-table v-else class="col-grow h-full"
                        :columns="['ID', 'Data', 'Tipo', 'Status', 'Opções']"
        >
          <e-tr v-for="(data, index) in model" :key="index">
            <e-td><span v-if="data.id">{{ data.id }}</span><span v-else class="text-primary">a registrar</span>
            </e-td>
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy') }}</e-td>
            <e-td>Vistoria sem OS de remoção</e-td>
            <e-td class="text-nowrap">
              <div v-if="status(data.status)" class="flex items-center no-wrap text-nowrap">
                <status-solicitacao :solicitacao="data" />
              </div>
            </e-td>
            <e-td>
              <a v-if="status(data.status)" @click="openSolicitacao(data.remocao.id, data.id)"><i class="erp-icon search min"></i> </a>
              <a v-if="!data.id" @click="$emit('edit', data)"><i class="fa fa-edit"></i> </a>
            </e-td>
          </e-tr>

          <e-table-footer slot="footer" class="flex flex-center">
            <e-table-footer-result class="m-r text-right">Total de remoções = {{model.length}}</e-table-footer-result>
            <e-table-footer-result class="m-r text-right">Remoções pendentes = {{pendentes}}</e-table-footer-result>
            <e-table-footer-result class="text-right">Remoções concluídas = {{concluidas}}</e-table-footer-result>
          </e-table-footer>
        </e-window-table>
      </div>
    </div>

    <!--<e-btn class="m-t" @click="novaRemocao" label="Nova solicitação" />-->
  </div>
</template>

<script>
import {ETableFooter, ETableFooterResult, ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../../domain/remocao/helpers/Status'
import StatusSolicitacao from '../../include/StatusSolicitacao'
import windowSolicitacao from '../../../windows/solicitacao'
import remocaoWindow from "components/remocao/windows/remocao"

export default {
  name: 'SolicitacoesRemocao',
  inject: ['remocaoContainer'],
  props: {
    solicitacoes: {
      required: true
    },
    title: {
      type: Boolean,
      default: true
    },
    bem: {required: false}
  },
  components: {
    StatusSolicitacao,
    EWindowTable,
    ETr,
    ETd,
    ETableFooter,
    ETableFooterResult
  },
  data () {
    return {
      model: this.solicitacoes
    }
  },
  watch: {
    solicitacoes (v) {
      console.log(v)
      this.$nextTick(() => {
        this.model = v
      })
    }
  },
  computed: {
    hasReboquista () {
      let has = false
      this.model.map(s => {
        if (s.reboquista) {
          has = true
        }
      })
      return has
    },
    concluidas () {
      if (!Array.isArray(this.model)) {
        return 0
      }
      return this.model.filter((item) => {
        return item.status && Number(item.status) === Number(Status.STATUS_SOLICITACAO_ENTREGUE)
      }).length
    },
    pendentes () {
      return this.model.length - this.concluidas
    }
  },
  methods: {
    getUf (d) {
      if (typeof d.uf === 'object') {
        return d.uf.nome || d.uf
      } else if (d.uf) {
        return d.uf
      }
      return d.nome
    },
    status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    },
    openSolicitacao: windowSolicitacao,
    remocaoWindow: remocaoWindow,
    novaRemocao () {
      console.log('New...')
      this.remocaoWindow(null, this.bem)
    },
  }
}
</script>
