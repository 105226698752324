import http from '../../../utils/services/http'

export const listEnableds = () => {
    let url = '/api/bens/marketplaces'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const imovelWebCriaAnuncio = (bemId) => {
    let url = `/api/bens/${bemId}/imovelweb/cria-anuncio`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const imovelWebDeletarAnuncio = (bemId) => {
    let url = `/api/bens/${bemId}/imovelweb/delete-anuncio`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const santanderImoveisTransmiteDevolucao = (loteId) => {
    let url = `/api/integra/santanderimoveis/transmitir-devolucao/${loteId}`
    return http.patch(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const detranPBComunicacaoVenda = async (loteId) => {
    // let url = `/api/integra/detranpb/checa-disponibilidade`
    let url = `/api/integra/detranpb/comunicacao-venda/${loteId}`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const resaleTransmiteUrl = (loteId) => {
    let url = `/api/lotes/${loteId}/resale/atualiza-url`
    return http.patch(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
