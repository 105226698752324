export default [
  {
    path: '/console', component: () => import('./Main.vue'), name: 'website.main', children: [
      {
        path: '',
        name: 'console.home',
        component: () => import('./components/Console')
      }
    ]
  }
]
