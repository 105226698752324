export default {
  title: 'Financeiro',
  default: 'hom',
  shortName: 'FIN',
  menu: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: 'fa-duotone fa-chart-simple fa-fw',
      forceIconClass: true,
      route: 'dashboard.main',
      url: '/#/'
    },
    {
      id: 'cadastros',
      title: 'Cadastros',
      icon: 'fa-duotone fa-people-group fa-fw',
      forceIconClass: true,
      style: 'light',
      route: 'dashboard.cadastros'
    },
    {
      id: 'bens',
      title: 'Resumo de Bens',
      icon: 'fa-duotone fa-car-building fa-fw',
      forceIconClass: true,
      style: 'light',
      route: 'dashboard.bens'
    },
    {
      id: 'suporte',
      title: 'Suporte',
      icon: 'fa-duotone fa-headset fa-fw',
      forceIconClass: true,
      style: 'light',
      route: 'suporte.cliente.dashboard'
    },
  ]
}
